import React, { useState, useEffect, useRef, useReducer } from "react";
import { Button, Dialog, Tooltip } from "@mui/material";
import moment from "moment";
import ZoomButtons from "components/ZoomButtons";
import axios from "axios";
import { APRYSE_L_KEY } from "api";
import { fetchUrl } from "Urls";
import { cloneDeep } from "lodash";
import WebViewer from "@pdftron/webviewer";
import ToastComponent from "components/ToastComponent";
import { ConfirmDialog } from "components/DrawerComponent/DisciplineSettingDialog";
import SearchIcon from "assets/images/search.svg";
import CreateProjectForm from "components/FormsComponent/CreateProjectForm";
const submitFormDialog = {
  title: 'Submit Form?',
  content: 'Are you sure you want to submit this form? Once submitted, you won’t be able to make any changes.',
  action1: "Cancel",
  action2: "Submit Form"
};
const BuildPdfForm = ({ projectFormId, credential, templateList, onCancel, ...props }) => {
    const [formId, setFormId] = useState(projectFormId);
    const [isFormCompleted, setIsFormCompletedState] = useState(false);
    const [sfObj, setsfObj] = React.useState(defaultObj());
    const sfObjRef = useRef(sfObj);
    const [showSpinner, setSpinner] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [editFormDetails, setEditFormDetails] = useState(false);
    const [refreshOnClose, setRefreshOnClose] = useState(false);
    const [disableSave, setDisableSave] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [pagesPanel, setPagesPanelState] = useState(false);
    const [instance, setInstanceState] = useState(null);
    const instanceRef = useRef(instance);
    const [searchInPdf, setSearchInPdf] = useState(false);
    const [currentZoomLevel, setCurrentZoomLevel] = useState(1);
    const [showZoomActions, setShowZoomAction] = useState(false);
    const [showSubmitWarning, setShowSubmitWarning] = useState(false);
    const [userValidationMatch, setUserValidationMatch] = useState(false);
    const [formAction, setFormAction] = useState(null);
    const [formObj, setFormObj] = React.useState(null);
    const [templateDetails, setTemplateDetails] = React.useState(null);
    const [formDialogData, setFormDialogData] = useState(null);
    const pagePanelRef = useRef(false);
    const isFormReadonly = useRef(null);
    var requiredMissing = useRef(null);
    const existingFileName = useRef(null);
    const viewer = useRef();
    const docTriggerChanges = useRef(false);
    function defaultObj() {
        //console.log('credential :: '+credential?.userList?.length);
        return {
            Project__c: credential.projectId,
            Status__c: props?.defaultStatus,
            Date__c: moment(new Date()).format("YYYY-MM-DD"),
        };
    }
    useEffect(() => {
        if (projectFormId) {
            getFormDetails(projectFormId);
        }
    }, []);
    function handleEditFormDetails(state, action) {
        setEditFormDetails(true)
    }
    async function onClose(state = refreshOnClose) {
        onCancel(state);
    }
    const handleToggleDrawerPanel = (section) => {
        //console.log('section , controlled  :: ', section, controlled);
        //console.log('handleToggleDrawerPanel instance ', instance);
        let leftPanelButton;
        if (instanceRef.current) {
            leftPanelButton = instanceRef.current.UI.iframeWindow.document.querySelector('[data-element="leftPanelButton"]');
        }
        if (section === "pages") {
            if (leftPanelButton) {
                leftPanelButton.click();
                setPagesPanel(!pagePanelRef.current);
            }
        }
    };
    const setPagesPanel = (value) => {
        pagePanelRef.current = value;
        setPagesPanelState(value);
    };
    function setIsFormCompleted(state) {
        isFormReadonly.current = state;
        setIsFormCompletedState(state);
    }
    const setInstance = (state) => {
        instanceRef.current = state;
        setInstanceState(state);
    };
    async function getFormDetails(_formId = formId) {
        let userValidation = false;
        setSpinner(true);
        await axios.get(`${fetchUrl}/getProjectFormDetail/${_formId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`)
            .then((res) => {
                //console.log("count:::: " + JSON.stringify(res?.data));
                if (res.status === 200) {
                    //{formObj : cObj , docDetail : docReturn}
                    if (res.data?.formObj) {
                        setIsFormCompleted(res.data?.formObj.Status__c == "Completed");
                        if(res.data?.formObj?.Ball_in_Court__c == credential.userId || res.data?.formObj?.CreatedById == credential.userId){
                            userValidation = true;
                            setUserValidationMatch(true);
                        }else{
                            userValidation = false;
                            setUserValidationMatch(false);  
                        }
                        setVariables(res.data.formObj);
                        if (res.data.docDetail) {
                            existingFileName.current = res.data.docDetail.File_Name__c;
                            initLoadPDF(res.data.docDetail.end_url, userValidation);
                        }
                        return;
                    }
                    setVariables(defaultObj());
                }
            })
            .catch((err) => {
                console.log(err);
            });
        setSpinner(false);
    }
    function setVariables(_sfObj) {
        sfObjRef.current = _sfObj;
        if (_sfObj?.Id) {
            setFormId(_sfObj.Id);
        }
        requiredMissing.current = !checkReadyToSave(sfObjRef.current);
        //console.log("_sfObj ::::", _sfObj);
        setsfObj(_sfObj);
    }
    function checkReadyToSave(_sfObj) {
        //console.log('checkReadyToSave');
        let reqfields = ["Form_Template__c", "Project__c", "Date__c", "Name"];
        if (_sfObj) {
            for (let f of reqfields) {
                if (!_sfObj[f] || _sfObj[f] == "" || _sfObj[f] == null) {
                    //console.log('checkReadyToSave :: '+f);
                    return false;
                }
            }
        }
        return true;
    }
    async function initLoadPDF(fileURI, userValidation) {
        if (viewer.current) {
            setShowZoomAction(false);
            const disElement = [
                "leftPanelTabs",
                "zoomThumbOutButton",
                "thumbnailsSizeSlider",
                "toolbarGroup-View",
                "toolsHeader",
                "toolbarGroup-Annotate",
                "toolbarGroup-Shapes",
                "toolbarGroup-Insert",
                "toolbarGroup-Measure",
                "toolbarGroup-Edit",
                "toolbarGroup-FillAndSign",
                "toolbarGroup-Forms",
                "toolbarGroup-Redact",
                "annotationGroupButton",
                "linkButton",
                "annotationCommentButton",
                "thumbnailControl",
                "documentControl",
                "toolStylePopup",
                "leftPanelResizeBar",
                "contextMenuPopup",
                "replacePage",
                "insertPage",
                "extractPage",
                "searchPanelResizeBar",
            ];/* 
          "annotationDeleteButton",
          "annotationStyleEditButton"*/
            await WebViewer({
                fullAPI: true, path: "/webviewer/public",
                initialDoc: fileURI,
                disabledElements: disElement,
                licenseKey: APRYSE_L_KEY
            }, viewer.current).then(async (instance) => {
                setShowZoomAction(true);
                setInstance(instance);
                const { documentViewer , annotationManager} = instance.Core;
                const formCreationManager = annotationManager.getFormFieldCreationManager();
                const iframeWindow = instance.UI.iframeWindow;
                const header = iframeWindow.document.querySelector('[data-element="header"]');
                header.style.display = 'none';

                documentViewer.addEventListener("documentLoaded", async () => {
                    documentViewer.addEventListener('annotationsLoaded', () => {
                        //console.log('apply fields');
                        if(userValidation){
                        formCreationManager.applyFormFields();
                        }
                    });
                    annotationManager.addEventListener('fieldChanged', (field, value) => {
                        //console.log(`Field changed: ${field.name}, ${value}`);
                        docTriggerChanges.current = true;
                    });
                    const docu = documentViewer.getDocument();
                    const pageCount = docu.getPageCount();
                    const leftPanelButton = iframeWindow.document.querySelector('[data-element="leftPanelButton"]');
                    const leftPanel = iframeWindow.document.querySelector('[data-element="leftPanel"]');
                    const thumbnailPanel = document.getElementById('thubmnail-panel');
                    if (thumbnailPanel && leftPanel) {
                        thumbnailPanel.addEventListener('click', () => {
                            if (pagePanelRef.current) {
                                const pdfContainer = leftPanel.querySelectorAll('.container');
                                pdfContainer.forEach(element => {
                                    element.style.height = "100px";
                                });
                            }
                        })
                    }
                    styleLibraryLeftPanel(leftPanel, pageCount, leftPanelButton);
                    async function styleLibraryLeftPanel(leftPanel, pageCount, leftPanelButton) {
                        const leftPanelContainer = leftPanel.querySelector('.left-panel-container');
                        leftPanelContainer.style.padding = "0px";
                        const leftPanelHeader = leftPanel.querySelector('.left-panel-header');
                        leftPanelHeader.style.backgroundColor = "white";
                        leftPanelHeader.style.margin = "0px";
                        leftPanelHeader.style.padding = "0px";
                        leftPanelHeader.style.borderBottom = "1px solid #EAEAEA";
                        const thumPanelTitle = iframeWindow.document.createElement('div');
                        thumPanelTitle.classList.add('thumb-panel-title');
                        thumPanelTitle.style.width = "100%";
                        const pdfContainer = iframeWindow.document.querySelectorAll('.container');
                        pdfContainer.forEach(element => { element.style.height = "100px"; });
                        const thumPanelTitleContainer = iframeWindow.document.createElement('div');
                        thumPanelTitleContainer.classList.add('thumb-panel-title-container');
                        thumPanelTitleContainer.style.display = "flex";
                        thumPanelTitleContainer.style.gap = "90px";
                        thumPanelTitleContainer.style.padding = "12px 16px";
                        thumPanelTitleContainer.style.borderBottom = "1px solid #EAEAEA";
                        thumPanelTitleContainer.style.width = "100%";
                        const titContainerInnerDiv = iframeWindow.document.createElement('div');
                        titContainerInnerDiv.classList.add('thumb-panel-title-container-text');
                        titContainerInnerDiv.style.display = "flex";
                        titContainerInnerDiv.style.gap = "90px";
                        titContainerInnerDiv.style.padding = "12px 16px";
                        titContainerInnerDiv.style.width = "100%";
                        titContainerInnerDiv.textContent = `${pageCount} Pages`;
                        titContainerInnerDiv.color = '#505050';
                        const leftIconContainer = iframeWindow.document.createElement('div');
                        const closeIconImg = iframeWindow.document.createElement('img');
                        closeIconImg.alt = 'Image Description';
                        closeIconImg.src = '/close.svg';
                        closeIconImg.style.cursor = "pointer";
                        closeIconImg.addEventListener('mouseover', function () {
                            closeIconImg.style.backgroundColor = '#F8F8F8';
                        });
                        closeIconImg.addEventListener('mouseout', function () {
                            closeIconImg.style.backgroundColor = 'initial'; // Reset to the default background color
                        });
                        closeIconImg.addEventListener('click', function () {
                            setPagesPanel(false);
                            leftPanelButton.click();
                        });
                        thumPanelTitleContainer.textContent = 'Pages';
                        thumPanelTitleContainer.style.fontWeight = 600;
                        thumPanelTitleContainer.style.fontSize = "14px";
                        thumPanelTitleContainer.style.color = "black";
                        leftPanelHeader.appendChild(thumPanelTitle);
                        thumPanelTitle.appendChild(thumPanelTitleContainer);
                        thumPanelTitle.appendChild(titContainerInnerDiv);
                        thumPanelTitleContainer.appendChild(leftIconContainer);
                        thumPanelTitleContainer.appendChild(closeIconImg);
                        leftPanel.style.backgroundColor = '#F8F8F8';
                        leftPanel.style.height = '100vh';
                        leftPanel.style.borderLeft = '1px solid #D8D8D8';
                        leftPanel.style.boxShadow = '2px 0px 3px #ccc';
                    }
                    setCurrentZoomLevel(documentViewer.getZoomLevel());
                    if (true) {
                        const zOutEle = document.getElementById('zoom-out-button');
                        if (zOutEle) {
                            if (!zOutEle.dataset?.clickBound || zOutEle.dataset.clickBound != 'yes') {
                                zOutEle.dataset.clickBound = 'yes';
                                zOutEle.addEventListener('click', () => {
                                    let preLevel = documentViewer.getZoomLevel();
                                    if (preLevel > 0.1) {
                                        documentViewer.zoomTo(documentViewer.getZoomLevel() - 0.25);
                                    } else {
                                        documentViewer.zoomTo(0.1);
                                    }
                                });
                            }
                        }
                        const zInEle = document.getElementById('zoom-in-button');
                        if (zInEle) {
                            if (!zInEle.dataset?.clickBound || zInEle.dataset.clickBound != 'yes') {
                                zInEle.dataset.clickBound = 'yes';
                                zInEle.addEventListener('click', () => {
                                    documentViewer.zoomTo(documentViewer.getZoomLevel() + 0.25);
                                });
                            }
                        }
                    }
                });
            }).catch((err) => {
                console.log('err', err);
            });
        }
    };
    const onClickSave = async ( buttonType , message )=>{
      const rec = JSON.parse(JSON.stringify(sfObjRef.current));
      const { documentViewer, annotationManager } = instanceRef.current.Core;
      const fieldManager = annotationManager.getFieldManager();
      let process = true;
      if(buttonType === 'submit'){
        process = fieldManager.areRequiredFieldsFilled();
      }
      console.log('process :: ',process);
      if(process){
        if(buttonType == "submit"){
          rec.Ball_in_Court__c = rec?.CreatedById ? rec?.CreatedById : credential?.userId ;
          rec.Status__c = 'Completed' ;
        }
        const doc = documentViewer.getDocument();
        const xfdfString = await annotationManager.exportAnnotations();
        console.log('save xfdfString ::',xfdfString);
        const options = { xfdfString };
        const data = await doc.getFileData(options);
        const arr = new Uint8Array(data);
        const blob = new Blob([arr], { type: 'application/pdf' });
        const image = URL.createObjectURL(blob);
        console.log('image :: ',image);
        const formData = new FormData();
        formData.append("templateFile", blob, existingFileName.current);
        formData.append("fileTitle", existingFileName.current);
        formData.append("putOnAmazon", true);
        formData.append("formRec", JSON.stringify(rec));
        return await axios.post(`${fetchUrl}/saveProjectForm/Form__c`, formData)
          .then((res) => {
            if(res.data){
                const {form , file_name} = res.data
                rec.Id = form.Id;
                if(file_name){
                    existingFileName.current = file_name;
                }
                setVariables(rec);
                setToastMessage(message);
                setIsFormCompleted(sfObj?.Status__c === 'Completed');
                setRefreshOnClose(true);
                if(sfObj?.Status__c === 'Completed'){
                  //setTemplateUpdated(true);
                }
                return true;
            }
          }).catch((err) => {
            console.log(err);
            setSpinner(false);
            return false;
         });
      }
      return false;
    };
    const onClickDownload = async ( )=>{
      const rec = sfObjRef.current;
      const { documentViewer, annotationManager } = instanceRef.current.Core;
      const doc = documentViewer.getDocument();
      const xfdfString = await annotationManager.exportAnnotations();
      const options = { xfdfString, flatten: true };
      console.log('save xfdfString ::',xfdfString);
      const data = await doc.getFileData(options);
      console.log('save onClickDownload ::',1);
      const arr = new Uint8Array(data);
      console.log('save onClickDownload ::',2);
      const blob = new Blob([arr], { type: 'application/pdf' });
      console.log('save onClickDownload ::',3);
      const image = URL.createObjectURL(blob);
      console.log('save image ::',image);
      const a = document.createElement("a");
      a.href = image;
      a.style = "display: none";
      a.download = rec.Name+'-'+existingFileName.current;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(image);
      document.body.removeChild(a);
    };
    function handleSearchInPdf(ann, type) {
        let pdfSearchButton;
        pdfSearchButton = instance.UI.iframeWindow.document.querySelector('[data-element="searchButton"]');
        pdfSearchButton.click();
        setSearchInPdf(!searchInPdf);
    
      }

      function handleFormAction(action) {
        let formsfObj = cloneDeep(sfObj);
        if(action == 'duplicate'){
        const { Id, ...updatedFormData } = formsfObj; // Destructure to remove `Id`
        updatedFormData.Name = `${formsfObj.Name} (1)`;
        const sObj = {value : formsfObj.Form_Template__c ,type : formsfObj.Form_Template__r.Form_Type__c , label : formsfObj.Form_Template__r?.Name };
        setTemplateDetails(sObj);
        setFormObj(updatedFormData);
        }else {
        setFormObj(formsfObj);  
        }
        let dialogData = {headerTitle : action === 'edit' ? 'Edit Form Details' :  'Duplicate Form', showSteps: false, showTemplateDetail: false, button1: 'Cancel', button2: action === 'edit' ? 'Save Changes' : 'Duplicate Form'}
        setFormDialogData(dialogData);
        setFormAction(action);
    }

    return (
        <>
            <Dialog open={true} fullScreen>
                <div className="builder">
                    <div className="builder_head" >
                        <div className="builder_head_left">
                            <div className="builder_head_left-container" onClick={() => onClose()}>
                                <div>
                                    <img src="/left.svg" alt="leftIcon" />
                                </div>
                                <div className="header_label">
                                    Go to Forms
                                </div>
                            </div>
                        </div>
                        <div className="builder_head_center">
                            {showSpinner &&
                                <div className="header_label_dark">Loading...</div>
                            }
                            {!showSpinner &&
                                <>
                                    <div className="header_label_dark">{sfObj?.Name}</div>
                                    {!isFormCompleted && userValidationMatch &&
                                        <div className="clickable custom_button" onClick={handleEditFormDetails}>
                                            <img src="/Pencil.svg" alt="leftIcon" />
                                        </div>
                                    }
                                </>
                            }
                        </div>
                        <div className="builder_head_right">
                            <div className="header_icon" style={{ border: (isFormCompleted || !userValidationMatch) && "none" }}>
                                {showZoomActions &&
                                    <img src={SearchIcon} alt="search_icon" onClick={handleSearchInPdf} style={{ backgroundColor: searchInPdf && "#E8EFFF", alignSelf: "center",  marginRight: "12px" }} />
                                }
                                {!isFormCompleted && userValidationMatch &&
                                        <Tooltip title={'Duplicate'} placement="bottom" PopperProps={{ className: "version-action-tooltip" }} arrow>
                                            <img style={{ cursor: "pointer", marginRight: "12px" }} src="/copy.svg" alt="leftIco" onClick={()=>{handleFormAction('duplicate')}}/>
                                        </Tooltip>
                                    }
                                <img style={{ cursor: "pointer" }} src="/download.svg" alt="leftIco" onClick={onClickDownload}/>
                            </div>
                            {!isFormCompleted && userValidationMatch &&
                                <>
                                    <div>
                                        <Button className="custom_button cancel_button" disabled={disableSave} onClick={() => { onClickSave('save', `${sfObj.Name} has been updated.`); }}>Save Changes</Button>
                                    </div>
                                    <div>
                                        <Button className="custom_button apply_button" disabled={disableSubmit} onClick={() => { setShowSubmitWarning(true); }}>
                                            Submit Form
                                        </Button>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    {disableSubmit &&
                        <div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "red", height: "48px", borderBottom: "1px solid #D8D8D8" }}>Please complete the checklist below</div>
                        </div>
                    }
                

                    <div className="builder_body" style={{ justifyContent: (isFormCompleted || !userValidationMatch) && "flex-start", paddingTop: !isFormCompleted && userValidationMatch && "0px", display: !isFormCompleted && userValidationMatch && "block"  }}>
                        {(isFormCompleted || !userValidationMatch) &&
                            <div className="builder_body_left" style={{ width: (isFormCompleted || !userValidationMatch) && "40%" }}>
                                <div className="builder_body_left-container">
                                    <div className="section" style={{ marginTop: !isFormCompleted && userValidationMatch && "16px" }}>
                                        <div className="section_head">
                                            <div>Form Details</div>
                                        </div>
                                        <div className="section_body">
                                            <div className="formDetail">
                                                <div className="formDetail_heading">Id</div>
                                                <div style={{ color: "#000000" }}>{sfObj?.Numbering_Sequence__c}</div>
                                            </div>
                                            <div className="formDetail">
                                                <div className="formDetail_heading">Form Name</div>
                                                <div style={{ color: "#000000" }}>{sfObj?.Name}</div>
                                            </div>
                                            <div className="formDetail">
                                                <div className="formDetail_heading">Form Type</div>
                                                <div style={{ color: "#000000" }}>{sfObj?.Form_Template__r?.Form_Type__c}</div>
                                            </div>
                                            <div className="formDetail">
                                                <div className="formDetail_heading">Category</div>
                                                <div style={{ color: "#000000" }}>{sfObj?.Form_Template__r?.Template_Category__c}</div>
                                            </div>
                                            <div className="formDetail">
                                                <div className="formDetail_heading">Description</div>
                                                <div style={{ color: "#000000" }}>{sfObj?.Description__c}</div>
                                            </div>
                                            <div className="formDetail">
                                                <div className="formDetail_heading">Status</div>
                                                <div style={{ color: "#000000" }}>  <img style={{ marginRight: "5px" }} src={sfObj?.Status__c == "Completed" ? "/closed-green-1.svg"
                                                    : sfObj?.Status__c == "In Progress" ? "/formsInProgess.svg"
                                                        : "/FormsNotStarted.svg"} alt="statusIcons" />{sfObj?.Status__c}</div>
                                            </div>
                                            <div className="formDetail">
                                                <div className="formDetail_heading">Location</div>
                                                <div style={{ color: "#000000" }}>-</div>
                                            </div>
                                            <div className="formDetail">
                                                <div className="formDetail_heading">Ball in court</div>
                                                <div style={{ color: "#000000" }}><img style={{ width: "20px", marginRight: "5px" }} src="/Ellipse 2.svg" alt="saaa" /> {sfObj?.Ball_in_Court__r?.Name}</div>
                                            </div>
                                            <div className="formDetail">
                                                <div className="formDetail_heading">Due date</div>
                                                <div style={{ color: "#000000" }}>{moment(sfObj?.Date__c).format('MM/DD/YYYY')}</div>
                                            </div>
                                            <div className="formDetail">
                                                <div className="formDetail_heading">Last Updated By</div>
                                                <div style={{ color: "#000000" }}><img style={{ width: "20px", marginRight: "5px" }} src="/Ellipse 2.svg" alt="saaa" /> {sfObj?.LastModifiedBy?.Name}</div>
                                            </div>
                                            <div className="formDetail">
                                                <div className="formDetail_heading">Last Updated</div>
                                                <div style={{ color: "#000000" }}>{moment(sfObj?.LastModifiedDate).format('MM/DD/YYYY')}</div>
                                            </div>
                                            <div className="formDetail">
                                                <div className="formDetail_heading">Created By</div>
                                                <div style={{ color: "#000000" }}><img style={{ width: "20px", marginRight: "5px" }} src="/Ellipse 2.svg" alt="saaa" /> {sfObj?.CreatedBy?.Name}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className={(isFormCompleted || !userValidationMatch) ? "builder_body_center" : ''}style={{ position: "relative", height: isFormCompleted ? "87vh" : disableSubmit ? "87vh" : "91vh", overflow: "hidden", display: !isFormCompleted && userValidationMatch && "flex" }}>
                            {(isFormCompleted || !userValidationMatch) &&
                                <div className="section_head" style={{ display: "flex" }}>
                                    <div>PDF Viewer</div>
                                    <div style={{ display: "flex", gap: "10px" }}>
                                        <div id="zoom-out-button" style={{ cursor: "pointer" }}>
                                            <img src="zoom_out.svg" alt="" />
                                        </div>
                                        <div id="zoom-in-button" style={{ cursor: "pointer" }}>
                                            <img src="zoom_in.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            }
                            {!isFormCompleted && userValidationMatch &&
                                <div className="siderbar_drawer_sheets">
                                    <Button id="thubmnail-panel-custom" className="markup_button_style" onClick={() => handleToggleDrawerPanel("pages")} >
                                        <img style={{ width: !pagesPanel && "15px" }} src={pagesPanel ? "/pages.svg" : "/pages-grey.svg"} alt="markup_icon" />
                                        <span style={{ color: !pagesPanel ? "rgba(80, 80, 80, 1)" : "rgba(52, 110, 245, 1)", }} >
                                            Pages
                                        </span>
                                    </Button>
                                    {showZoomActions && (
                                    <ZoomButtons zoomLevel={currentZoomLevel} />
                                )}
                                </div>
                            }
                            <div id="extractPDFcontainer" className="webviewer" ref={viewer} style={{ width: "100%", height: "94vh" }} />
                        </div>
                    </div>
                </div>
            </Dialog>
            {showSubmitWarning &&
                <ConfirmDialog
                    DialogData={submitFormDialog}
                    onAccept={async () => {
                        setShowSubmitWarning(false);
                        onClickSave('submit', `${sfObj.Name} has been submitted for review.`);
                    }}
                    onCancel={() => {
                        setShowSubmitWarning(false);
                    }}
                />
            }
            {formAction &&
                <CreateProjectForm
                    dialogData={formDialogData}
                    sfFormDetail={formObj}
                    sfObj={templateDetails}
                    templateOptions={templateList}
                    credential={credential}
                    currentStep={2}
                    onCancel={(refresh, redirectId) => {
                        if (formAction === 'edit') {
                            setFormAction(null);
                        } else {
                            setFormAction(null);
                            if(redirectId){
                            getFormDetails(redirectId);
                            setToastMessage('Form has been duplicated');
                            }
                        }
                    }}
                    onAccept={async (sfreocrd) => {
                        //console.log('sfreocrd ::', sfreocrd);
                        setSpinner(true);
                        let rec = cloneDeep(sfreocrd);
                        if (sfreocrd?.Id) {
                            delete rec.Form_Template__c;
                        }
                        setFormAction(null);
                        setVariables(sfreocrd);
                        const formData = new FormData();
                        //console.log("rec ::", rec);
                        formData.append("recObj", JSON.stringify(rec));
                        await axios.post(`${fetchUrl}/recSave/Form__c`, formData)
                            .then(async (res) => {
                                //console.log("count:::: " + JSON.stringify(res));
                                setSpinner(false);
                                if (res.data.status === 200) {
                                    sfreocrd.Id = res.data.retObj.id;
                                    setVariables(sfreocrd);
                                    setToastMessage('Form Details has been updated.');
                                    setRefreshOnClose(true);
                                    getFormDetails(sfreocrd.Id);
                                }
                            }).catch((err) => {
                                console.log('recSave/Form__c', err);
                                setSpinner(false);
                            });
                    }}
                />
            }
            {toastMessage &&
                <ToastComponent message={toastMessage} handleClose={() => setToastMessage(null)} />
            }
        </>
    );
}
export default BuildPdfForm;