import { useState, useEffect, useRef } from "react";
import _ from "lodash";
import moment from "moment";
import DropdownFilter from "components/DropdownFilter";
import axios from "axios";
import { fetchUrl } from "Urls";
import { highlightText } from "components/SheetsComponent";
import ReactDataTable from "components/ReactDataTableComponent";
import BuildChecklistTemplate from "components/FormTemplateComponent/BuildChecklistTemplate";
import BuildPdfTemplate from "components/FormTemplateComponent/BuildPdfTemplate";
import BuildPdfForm from "components/FormsComponent/BuildPdfForm";
import ChecklistTemplateDetail from "./BuildChecklistTemplate/ChecklistTemplateDetail";
import { Box, TextField, InputAdornment, Checkbox, Tooltip, Stack, Paper, Menu, IconButton } from "@mui/material";
import Design from "components/SheetsComponent/Design";
import { Search } from "@mui/icons-material";
import FileUploadProgress from "components/MultifileProcessing/multiFileProcessing";
import ImportTemplates from "./ImportTemplates";
import Spinner from "components/spinner";
import { cloneDeep } from "lodash";
import ToastComponent from "components/ToastComponent";
import { ConfirmDialog } from "components/DrawerComponent/DisciplineSettingDialog";
import CreateProjectForm from "components/FormsComponent/CreateProjectForm";
import BuildChecklistForm from "components/FormsComponent/BuildChecklistForm";
const FormTemplateComponent = (props) => {
  const { credential, addCheckListButton, _templateOptions, refreshFormTableList, addPdfButton, importTemplatesButton, onClose, projectFormTemplates, userFilterOptions, fetchTemplateList, showTemplateSpinner } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const processTofilter_ = useRef(null);
  const [showSpinner, setSpinner] = useState(false);
  const [templateTypeActiveFilters, setTemplateTypeActiveFilters] = useState([]);
  const [templateCategoryActiveFilters, setTemplateCategoryActiveFilters] = useState([]);
  const [createdByActiveFilters, setCreatedByActiveFilters] = useState([]);
  const [updatedByActiveFilters, setUpdatedByActiveFilters] = useState([]);
  const [totalFilterApplied, setTotalFilterApplied] = useState(false);
  const [activeFilterDropdown, setActiveFilterDropdown] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchBoxFilter, setSearchBoxFilter] = useState(false);
  const [filterOptionsVisible, showFilterOptions] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState([]);
  const [selectedMenuRow, setSelectedMenuRow] = useState();
  const [warningDialodData, setWarningDialodData] = useState();
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [openBuildChecklist, setOpenBuildChecklist] = useState(false);
  const [openImportTemplates, setOpenImportTemplates] = useState(false);
  const [openBuildPdf, setOpenBuildPdf] = useState(false);
  const [templateId, setTemplateId] = useState();
  const [projectFormId, openedProjectForm] = useState(null);
  const [forEditTemplateDetail, setEditTemplateDetail] = useState();
  const [createForm, setCreateForm] = useState();
  const [projectTemplateId, setProjectTemplateId] = useState();
  const [importDialog, setImportDialog] = useState();
  const [templateOptions, setTemplateOptions] = useState(_templateOptions);
  const [tableFormList, setTableFormList] = useState([]);
  const [projectTemplateFormList, setProjectTemplateFormList] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const currentTabTemplateListRef = useRef([]);
  const [viewTableData, setViewTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [toastMessage, setToastMessage] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [formTypeForBuild, setFormTypeForBuild] = useState(null);
  const [templatesForImport, setTemplatesForImport] = useState(null);
  const [isTemplatesImported, setIsTemplatesImported] = useState(null);
  const [createFormDialogData, setCreateFormDialogData] = useState(null);
  const TemplatesDataColumns = [
    {
      id: "checkbox",
      accessor: "",
      disableSortBy: true,
      Header: (
        <Checkbox
          style={{ padding: "0px", marginLeft: "10px" }}
          sx={{
            padding: "0px",
            "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" },
          }}
          checked={selectedRowIds.length > 0 && selectedRowIds.length === rowsPerPage?.length}
          onChange={(e) =>{
            onRowSelectionAllChange(e.target.checked);
          }}
        />
      ),
      Cell: (rowInfo) => {
        return (
          <Checkbox
            style={{ padding: "0px", marginLeft: "10px" }}
            sx={{ "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" } }}
            type="checkbox"
            className="checkbox"
            checked={selectedRows.filter((row) => row.id === rowInfo?.row?.original?.id).length > 0}
            onChange={(e) =>
              onRowSelectionChange(rowInfo?.row?.original?.id, e.target.checked)
            }
          />
        );
      },
      overrideStyle: { width: "4%" },
    },
    {
      accessor: "title",
      Header: "Name",
      flex: 1,
      overrideStyle: { width: "15%" },
      Cell: (params) => {
        return (
          <Tooltip title={params.row?.original?.title} placement="bottom" >
          <div style={{ display: "flex" }} onClick={() => {
            setTemplateId(params.row.original.id);
            setProjectTemplateId(params.row.original.projectTemplateId);
            if (params.row.original.type === 'Checklist') {
              setOpenBuildChecklist(true);
            } else {
              setOpenBuildPdf(true)
            }
          }}
          onContextMenu={(e) => {
            let params_ = { id: params.row.original.id, row: params.row.original };
            handleClickMenu(e , params_);
          }}>
            <div style={{ gap: "5px", display: "flex", cursor: 'pointer'}}>
            <img src="/template.svg" alt="icon" />
              <span style={{ color: "#505050", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: "200px"}}
                dangerouslySetInnerHTML={{
                  __html: params.row?.original?.title_mark
                    ? params.row?.original?.title_mark
                    : params.row?.original?.title,
                }}
              />
            </div>
          </div>
          </Tooltip>
        );
      },
    },
    {
      accessor: "type",
      Header: "Type",
      flex: 1,
      overrideStyle: { width: "15%" },
    },
    {
      accessor: "category",
      Header: "Category",
      flex: 1,
      overrideStyle: { width: "15%" },
    },
    {
      accessor: "instance",
      Header: "Instance",
      flex: 1,
      overrideStyle: { width: "15%" },
      Cell: (params) => {
        return (
          <div style={{ display: "flex" }}>
            <div>
              <img src="/templateInstanceIcon.svg" alt="icon" />
            </div>
            <div style={{ marginLeft: "5px", color: "#505050" }}>
              {params.row.original.instance}
            </div>
          </div>
        );
      },
    },
    {
      accessor: "status",
      Header: "Status",
      flex: 1,
      overrideStyle: { width: "15%" },
      Cell: (params) => {
        return (
          <div style={{ display: "flex" }}>
            <div>
              {params.row.original.status &&
                <img
                  src={
                    params.row.original.status == "Inactive"
                      ? "/templatInactiveStatus.svg"
                      : params.row.original.status == "Draft"
                        ? "/draft.svg"
                        : "/approved.svg"
                  }
                  alt="icon"
                />
              }
            </div>
            <div style={{ marginLeft: "5px", color: "#505050" }}>
              {params.row.original.status}
            </div>
          </div>
        );
      },
    },
    {
      accessor: "createdBy",
      Header: "Created By",
      flex: 1,
      overrideStyle: { width: "15%" },
    },
    {
      accessor: "lastUpdatedOn",
      Header: "Last Updated",
      flex: 1,
      overrideStyle: { width: "10%" },
      disableSortBy: true,
    },
    {
      accessor: "updatedBy",
      Header: "Last Updated By",
      flex: 1,
      overrideStyle: { width: "10%" },
      disableSortBy: true,
    },
    {
      accessor: "settings",
      Header: "settings",
      flex: 1,
      disableSortBy: true,
      overrideStyle: { width: "4%" },
    },
  ];

  useEffect(() => {
    currentTabTemplateListRef.current = projectFormTemplates;
    setProjectTemplateFormList(projectFormTemplates);
    setViewTableData(projectFormTemplates);
  }, [projectFormTemplates]);

  useEffect(() => {
      setSpinner(showTemplateSpinner);
  }, [showTemplateSpinner]);

  useEffect(() => {
    let cc = 0;
    if (templateTypeActiveFilters.length > 0) {
      cc += templateTypeActiveFilters.length;
    }
    if (templateCategoryActiveFilters.length > 0) {
      cc += templateCategoryActiveFilters.length;
    }
    if (createdByActiveFilters.length > 0) {
      cc += createdByActiveFilters.length;
    }
    if (updatedByActiveFilters.length > 0) {
      cc += updatedByActiveFilters.length;
    }
    setTotalFilterApplied(cc);
    if (processTofilter_.current) {
      onHandleFilterData(cc);
    }
    processTofilter_.current = false;
  }, [ templateTypeActiveFilters, templateCategoryActiveFilters, createdByActiveFilters, updatedByActiveFilters, ]);
  async function onHandleFilterData(_totalFilterApplied = totalFilterApplied) {
    //console.log("running ::");
    let filteredFoundList = [];
    let orgData = cloneDeep(currentTabTemplateListRef?.current);
    //console.log("orgData :::", orgData);
    if (orgData?.length > 0 && _totalFilterApplied > 0) {
      for (const currRow of orgData) {
        let matched = true;
        if (templateTypeActiveFilters?.length > 0 && matched === true) {
          matched = false;
          if (currRow.type) {
            matched = templateTypeActiveFilters.includes(currRow.type);
          }
        }
        if (templateCategoryActiveFilters?.length > 0 && matched === true) {
          matched = false;
          if (currRow.category) {
            matched = templateCategoryActiveFilters.includes(currRow.category);
          }
        }
        if (createdByActiveFilters?.length > 0 && matched === true) {
          matched = false;
          const valueFound = createdByActiveFilters.find(
            (usss) => usss.value === currRow.createdById
          );
          if (valueFound) {
            matched = true;
          }
        }
        if (updatedByActiveFilters?.length > 0 && matched === true) {
          matched = false;
          const valueFound = updatedByActiveFilters.find(
            (usss) => usss.value === currRow.updatedById
          );
          if (valueFound) {
            matched = true;
          }
        }
        if (matched) {
          filteredFoundList.push(currRow);
        }
      }
    } else {
      filteredFoundList = orgData;
    }
    //console.log('onHandleFilterData filteredFoundList :: '+filteredFoundList?.length);
    setFilteredTableData(filteredFoundList);
    setViewTableData(filteredFoundList);
  }
  async function refreshTableList(_credential = credential) {
    handleCloseIcon();
    setProjectTemplateFormList([]);
    setViewTableData([]);
    fetchTemplateList();
  }
  const importingFiles = [{fileName: "ashok sharma", imported : true},
  {fileName: "samrat ashok", imported : false}
]
  const onRowSelectionAllChange = (isChecked) => {
    setSelectedRowIds([]);
    let rowIds = [];
    //console.log('rowsPerPage :: ',rowsPerPage?.length,isChecked);
    if (isChecked === true) {
      rowsPerPage.map((row) => rowIds?.push(row.id));
    }
    setSelectedRowIds(rowIds);
    const selRowIds = new Set(rowIds);
    const seleFolRows = viewTableData.filter((row) =>selRowIds.has(row.id.toString()));
    setSelectedRows(seleFolRows);
  }
  const onRowSelectionChange = (rowId, isChecked) => {
    //console.log('rowsPerPage :: ',rowsPerPage?.length,rowId,isChecked,selectedRowIds?.length);
    let rowIds = [];
    if (isChecked === true) {
      rowIds = selectedRowIds.length > 0 ? selectedRowIds : [];
      rowIds?.push(rowId);
    } else {
      rowIds = selectedRowIds.length > 0 ? selectedRowIds.filter((item) => item !== rowId) : [];
    }
    //console.log('rowIds :: ',rowIds?.length);
    setSelectedRowIds(rowIds);
    const selRowIds = new Set(rowIds);
    const seleFolRows = viewTableData.filter((row) =>selRowIds.has(row.id.toString()));
    setSelectedRows(seleFolRows);
  };
  useEffect(() => {
    setTemplateOptions(_templateOptions);
  }, [_templateOptions]);
  //console.log('Temp templateOptions :: ',_templateOptions ,templateOptions);
  const handleClickMenu = (event, parms) => {
    //console.log('handleClickMenu :: ',parms);
    event.preventDefault();
    event.stopPropagation();
    setSelectedMenuRow(parms.row);
    //setAnchorEl(event.currentTarget);
		setAnchorEl({ mouseX: event.clientX, mouseY: event.clientY });
  };
 
  const handleClearAllFilters = () => {
    processTofilter_.current = true;
    setActiveFilterDropdown(undefined);
    setTemplateTypeActiveFilters([]);
    setTemplateCategoryActiveFilters([]);
    setCreatedByActiveFilters([]);
    setUpdatedByActiveFilters([]);
    onHandleFilterData(0);
  };
  async function searchListViewFunction(searchTerm) {
    setSearchBoxFilter(false);
    setSearchText(searchTerm);
    let orgData = filteredTableData?.length > 0 || totalFilterApplied > 0 ? cloneDeep(filteredTableData) : cloneDeep(projectTemplateFormList);
    //console.log(searchTerm,'orgData :: '+orgData?.length);
    let searchFoundList = orgData;
    if (orgData) {
      if (searchTerm && searchTerm !== null && searchTerm !== "") {
        searchFoundList = [];
        for (let el of orgData) {
          if ((el.title && el.title.toLowerCase().includes(searchTerm))) {
            if (el.title && el.title.toLowerCase().includes(searchTerm)) {
              el.title_mark = highlightText(el.title, searchTerm);
            }
            searchFoundList.push(el);
          }
        }
        setSearchBoxFilter(true);
      }
    }
    //console.log(searchTerm, 'searchFoundList :: ' + searchFoundList?.length);
    setViewTableData(searchFoundList);
  }
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedMenuRow(null);
  };
  async function onClickOpenAction(action, clickSource) {
    //console.log('handleCloseMenu called' , selectedMenuRow);
    //console.log('handleCloseMenu called' , selectedRows);
    let dialogData;
    if (clickSource == "menu" && action === 'Inactive') {
      const rowItem = clickSource === 'menu' ? selectedMenuRow : "";
      dialogData = {
        title: `Deactivate Template?`,
        content: `Are you sure you want to deactivate this template? Once inactive, template will not be available for use.`,
        action1: "Cancel",
        action2: "Deactivate",
        source: clickSource,
        operation: action,
        operationItems : [rowItem]
      };
      setWarningDialodData(dialogData);
    } else if (clickSource == "menu" && action === 'Active') {
      const rowItem = clickSource === 'menu' ? selectedMenuRow : "";
      dialogData = {
        title: `Activate Template?`,
        content: `Are you sure you want to activate this template? Once active, template will be available for use.`,
        action1: "Cancel",
        action2: "Activate",
        source: clickSource,
        operation: action,
        operationItems : [rowItem]
      };
      setWarningDialodData(dialogData);
    }else if (clickSource == "header" && action === 'Inactive') {
      const rowItem = clickSource === 'menu' ? selectedMenuRow : "";
      dialogData = {
        title: `Deactivate Template${selectedRows.length > 1 ? "s" : "" }?`,
        content: `Are you sure you want to deactivate the selected template${selectedRows.length > 1 ? "s" : "" }? Once inactive, Template${selectedRows.length > 1 ? "s" : "" } will not be available for use.`,
        action1: "Cancel",
        action2: "Deactivate",
        source: clickSource,
        operation: action,
        operationItems : [rowItem]
      };
      setWarningDialodData(dialogData);
    } else if (clickSource == "header" && action === 'Active') {
      const rowItem = clickSource === 'menu' ? selectedMenuRow : "";
      dialogData = {
        title: `Activate Template${selectedRows.length > 1 ? "s" : "" }?`,
        content: `Are you sure you want to activate the selected template${selectedRows.length > 1 ? "s" : "" }? Once active, Template${selectedRows.length > 1 ? "s" : "" } will be available for use.`,
        action1: "Cancel",
        action2: "Activate",
        source: clickSource,
        operation: action,
        operationItems : [rowItem]
      };
      setWarningDialodData(dialogData);
    }else if(clickSource == "menu" && action === 'Delete'){
      dialogData = {
        title: `Delete Template?`,
        content: `Are you sure you want to delete this template? Once deleted, it cannot be recovered.`,
        action1: "Cancel",
        action2: "Delete",
        source: clickSource,
        operation: action,
        operationItems : [selectedMenuRow]
      };
      setWarningDialodData(dialogData);
    }
    else if(clickSource == "header" && action === 'Delete'){
      dialogData = {
        title: `Delete Template${selectedRows.length > 1 ? "s" : "" }?`,
        content: `Are you sure you want to delete the selected template${selectedRows.length > 1 ? "s" : "" }?  The deleted template${selectedRows.length > 1 ? "s" : "" } cannot be recovered.`,
        action1: "Cancel",
        action2: "Delete",
        source: clickSource,
        operation: action,
        operationItems : clickSource === 'menu' ? selectedMenuRow : selectedRows 
      };
      setWarningDialodData(dialogData);
    }else if( action === 'edit_details' ){
      const rowItem = clickSource === "menu" ? selectedMenuRow : selectedRows[0]
      const sObj = {Id : rowItem.Id ,Template_Category__c : rowItem.Template_Category__c , Name : rowItem?.Name };
      sObj.Description__c = rowItem?.Description__c;
      setEditTemplateDetail(sObj);
    }
    else if( action === 'create_form' ){
      const rowItem = clickSource === "menu" ? selectedMenuRow : selectedRows[0]
      const sObj = {value : rowItem.Id ,type : rowItem.Form_Type__c , label : rowItem?.title };
      sObj.Description__c = rowItem?.Description__c;
      handleFormDialogData();
      setCreateForm(sObj);
    }
    setAnchorEl(null);
  }
  const handleFormDialogData = async () => {
    let dialogData = {headerTitle : 'Create Form', showSteps: false, showTemplateDetail: true, button1: 'Cancel', button2: "Create Form"}
   setCreateFormDialogData(dialogData);
  }

  const onactiveInactive = async (status_, selectedMenu) => {
    setWarningDialodData(null)
    setSpinner(true);
    const formData = new FormData();
    let recTosave = {Id: selectedMenu.projectTemplate.Id, Status__c: status_};
    formData.append("recObj", JSON.stringify(recTosave));
    await axios.post(`${fetchUrl}/recSave/Project_Form_Template__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
    .then((res) => {
      let tmsg = `Template has been ${status_ == "Inactive" ? "Deactivate" : "Activate"}.`;
      refreshTableList();
      setToastMessage(tmsg);
    })
  }
  const onDeleteTemplate = async (status_, selectedMenu) => {
    if(selectedMenu.status == "Draft"){
    setWarningDialodData(null)
    setSpinner(true);
    await axios.delete(`${fetchUrl}/delete/Form_Template__c/${selectedMenu.id}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`).then((res) => {
      let tmsg = `Template has been deleted.`;
      refreshTableList();
      setToastMessage(tmsg);
    })
    .catch((err) => {
      console.log(err);
    });
  }
  }
  const deleteSelectedItems = async (status_, selectedMenu) => {
    if (selectedRows.length > 0) {
      setWarningDialodData(null)
      setSpinner(true);
      let k = 0;
      Promise.all(selectedRows).then((values) => {
        values.forEach(async (f) => {
          if (f.status == "Draft") {
            await axios.delete(
              `${fetchUrl}/delete/Form_Template__c/${f.id}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`
            ).then((res) => {
            })
          }
          k++;
          if (k === selectedRows.length) {
            let tmsg = `Template${selectedRows.length > 1 ? "s" : ""} has been deleted.`;
            setToastMessage(tmsg);
            refreshTableList();
          }
        });
      });
    }
  };
  const activeInactiveSelectedItems = async (status_, selectedMenu) => {
    if (selectedRows.length > 0) {
      setWarningDialodData(null)
      setSpinner(true);
      let k = 0;
      Promise.all(selectedRows).then((values) => {
        values.forEach(async (f) => {
          const formData = new FormData();
          let recTosave = {Id: f.projectTemplate.Id, Status__c: status_};
          formData.append("recObj", JSON.stringify(recTosave));
          await axios.post(`${fetchUrl}/recSave/Project_Form_Template__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
          .then((res) => {
          })
          k++;
          if (k === selectedRows.length) {
            let tmsg = `Template${selectedRows.length > 1 ? "s" : ""} has been ${status_ == "Inactive" ? "Deactivate" : "Activate"}.`;
            setToastMessage(tmsg);
            refreshTableList();
          }
        });
      });
    }
  };
  const handleCloseIcon = () => {
    setSelectedRowIds([]);
    setSelectedRows([]);
    setWarningDialodData(null);
  };
  function _handleCloseProcess() {
    setTemplatesForImport([]);
  }

  return (
    <div className="page_body">
      <div ref={addCheckListButton} id='add_new_checklist' style={{ display: 'none' }}
        onClick={() => {
          setTemplateId(null);
          setOpenBuildChecklist(true)
        }}
      />
      <div ref={importTemplatesButton} id='add_new_checklist' style={{ display: 'none' }}
        onClick={() => {
          setOpenImportTemplates(true);
        }}
      />
       <div ref={addPdfButton} id='add_new_checklist' style={{ display: 'none' }}
        onClick={() => {
          setTemplateId(null);
          setOpenBuildPdf(true)
        }}
      />
      <div style={{ width: "100%" }}>
        <div className="page_body_right_head">
          <div className="page_body_right_head_left_side">
            <div style={{ display: "flex", alignSelf: "center", marginRight: "10px", borderRight: "1px solid #EAEAEA", }} >
              {selectedRowIds.length > 0 && (
                <div className="buttons" style={{ paddingBottom: "4px", cursor: "pointer" }} onClick={handleCloseIcon}>
                  <img className="close" src="close.svg" alt="close" style={{ cursor: "pointer" }} />
                </div>
              )}
              <div style={{ marginRight: "10px" }}>
                <h2>{selectedRowIds.length} Selected</h2>
              </div>
            </div>
            {selectedRowIds.length > 0 && (
              <div
                style={{
                  display: "flex",
                }}
              >
                {selectedRowIds?.length === 1 && 
                  <div style={{ border: "1px solid #EAEAEA" }} onClick={()=>{onClickOpenAction('edit_details','header');}}>
                    <Tooltip PopperProps={{ className: "version-action-tooltip" }} title="Edit Template Details" placement="top" arrow>
                      <IconButton sx={{ borderRadius: 0 }}>
                        <img src="/Pencil.svg" alt="share" />
                      </IconButton>
                    </Tooltip>
                  </div>
                }
                {selectedRows?.length > 0 && (selectedRows?.filter((tem)=> tem.stage == 'Draft')).length === selectedRows?.length &&
                  <div style={{ border: "1px solid #EAEAEA" }} onClick={() => { onClickOpenAction('Delete', 'header') }}>
                    <Tooltip PopperProps={{ className: "version-action-tooltip" }} title="Delete" placement="top" arrow>
                      <IconButton sx={{ borderRadius: 0 }}>
                        <img src="/delete.svg" alt="share" />
                      </IconButton>
                    </Tooltip>
                  </div>
                }
                {selectedRows?.length > 0 && (selectedRows?.filter((tem)=> tem.status == 'Active')).length === selectedRows?.length &&
                  <div style={{ border: "1px solid #EAEAEA" }} onClick={() => { onClickOpenAction('Inactive', 'header') }}>
                    <Tooltip PopperProps={{ className: "version-action-tooltip" }} title="Deactivate" placement="top" arrow>
                      <IconButton sx={{ borderRadius: 0 }}>
                        <img src="/inactive.svg" alt="share" />
                      </IconButton>
                    </Tooltip>
                  </div>
                }
                {selectedRows?.length > 0 && (selectedRows?.filter((tem)=> tem.status == 'Inactive')).length === selectedRows?.length &&
                  <div style={{ border: "1px solid #EAEAEA" }} onClick={() => { onClickOpenAction('Active', 'header') }}>
                    <Tooltip PopperProps={{ className: "version-action-tooltip" }} title="Activate" placement="top" arrow>
                      <IconButton sx={{ borderRadius: 0 }}>
                        <img style={{width: "17px"}} src="/approved_active.svg" alt="share" />
                      </IconButton>
                    </Tooltip>
                  </div>
                }
              </div>
            )}
          </div>
          <div className="page_body_right_head_right_side">
            <div>
              <TextField
                sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px", width: "200px", }, }}
                id="outlined-basic"
                variant="outlined"
                value={searchText}
                fullWidth
                className="Searchbar-field"
                autoComplete="off"
                placeholder="Search Template"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {searchBoxFilter ? (
                        <img
                          style={{ width: "25px", cursor: "pointer" }}
                          src="/cancel.svg"
                          alt="close icon"
                          onClick={() => {
                            searchListViewFunction("");
                            setSearchText("");
                          }}
                        />
                      ) : (
                        <Search />
                      )}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  //convert input text to lower case
                  var lowerCase = e.target.value.toLowerCase();
                  if (!lowerCase || lowerCase === null || lowerCase === "") {
                    searchListViewFunction(lowerCase);
                  }
                  setSearchText(lowerCase);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    // Do code here
                    e.preventDefault();
                    var lowerCase = e.target.value.toLowerCase();
                    //console.log('searchText ',searchText , 'lowerCase',lowerCase);
                    searchListViewFunction(searchText);
                  }
                }}
              />
            </div>
            <div className={totalFilterApplied > 0 ? filterOptionsVisible ? "grid-11" : "grid-111" : filterOptionsVisible ? "grid-1" : "grid"}
              onClick={() => {
                showFilterOptions(!filterOptionsVisible);
              }}>
              <img className="filter2" src="/filter2 (1).svg" alt="filter2" />
              <div className="label-11 poppins-normal-abbey-14px">
                Filters{" "}{totalFilterApplied > 0 && (<>{" (" + totalFilterApplied + ")"}</>)}
              </div>
              {!filterOptionsVisible && totalFilterApplied > 0 && (
                <div className={filterOptionsVisible ? "grid-1" : "grid-2"} onClick={handleClearAllFilters}>
                  <div className="clear1">
                    <img className="clear" src="close.svg" alt="close" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {searchBoxFilter && (
          <div className="bottom_area" style={{ paddingBottom: "16px", paddingLeft: "16px", fontSize: "14px", fontWeight: 400, }} >
            {searchBoxFilter && (
              <>
                {" "}
                {viewTableData.length} Search Results for{" "}
                <b>"{searchText}"</b>
              </>
            )}
          </div>
        )}
        {filterOptionsVisible && (
          <Design>
            <div className="bottom_area" style={{ paddingBottom: "16px" }}>
              <div className="filters">
                <DropdownFilter
                  dropLabel="Tamplate Type"
                  isValueArray={true}
                  options={templateOptions?.checkListOptions?.Form_Type__c?.valueList}
                  activeFilterDropdown={activeFilterDropdown}
                  setActiveFilterDropdown={setActiveFilterDropdown}
                  activeFilters={templateTypeActiveFilters}
                  onFilterChange={async (newValues) => {
                    processTofilter_.current = true;
                    setTemplateTypeActiveFilters(newValues);
                  }}
                />
                <DropdownFilter
                  dropLabel="Category"
                  isValueArray={true}
                  options={templateOptions?.checkListOptions?.Template_Category__c?.valueList}
                  activeFilterDropdown={activeFilterDropdown}
                  setActiveFilterDropdown={setActiveFilterDropdown}
                  activeFilters={templateCategoryActiveFilters}
                  onFilterChange={async (newValues) => {
                    processTofilter_.current = true;
                    setTemplateCategoryActiveFilters(newValues)
                  }}
                />
                <DropdownFilter
                  dropLabel="Created By"
                  options={userFilterOptions}
                  activeFilterDropdown={activeFilterDropdown}
                  setActiveFilterDropdown={setActiveFilterDropdown}
                  activeFilters={createdByActiveFilters}
                  onFilterChange={async (newValues) => {
                    processTofilter_.current = true;
                    setCreatedByActiveFilters(newValues);
                  }}
                />
                <DropdownFilter
                  credential={credential}
                  dropLabel="Last Updated By"
                  options={userFilterOptions}
                  activeFilterDropdown={activeFilterDropdown}
                  setActiveFilterDropdown={setActiveFilterDropdown}
                  activeFilters={updatedByActiveFilters}
                  onFilterChange={async (newValues) => {
                    processTofilter_.current = true;
                    setUpdatedByActiveFilters(newValues);
                  }}
                />
                {totalFilterApplied > 0 && (
                  <>
                    <div style={{ cursor: "pointer" }} className="tertiary" onClick={handleClearAllFilters}>
                      <img className="reset" src="reset.svg" alt="reset" />
                      <div className="filters">Clear All</div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Design>
        )}
        <Box style={{ position: "relative", height: filterOptionsVisible ? "calc(100vh - 230px)" : "calc(100vh - 180px)", }} >
          {showSpinner && <Spinner />}
          <ReactDataTable
            errorMessage={""}
            pagination={false}
            setRowsPerPage={setRowsPerPage}
            columns_react_table={TemplatesDataColumns}
            rowss={viewTableData}
            openMenu={openMenu}
            handleClickMenu={handleClickMenu}
            selectedIds={selectedRowIds}
            listCount={0}
            searchBoxFilter={searchBoxFilter}
          />
        </Box>
        {openMenu && selectedMenuRow && (
          <>
            <Stack direction="row" spacing={2}>
              <Paper sx={{ height: 320, maxWidth: "100%" }}>
                <Menu id="basic-menu" anchorEl={anchorEl} open={openMenu} 
                  onClose={handleCloseMenu}
                  autoFocus
                  anchorReference="anchorPosition"
                  anchorPosition={
                    anchorEl
                      ? { top: anchorEl.mouseY, left: anchorEl.mouseX }
                      : undefined
                  }
                  transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                  }}
                  MenuListProps={{ "aria-labelledby": "basic-button" }}
                  onContextMenu={(e)=>{
                    e.preventDefault();
                    e.stopPropagation();
                    handleCloseMenu();
                  }}>
                  <div className="dropdown-list">
                    <>
                      {selectedMenuRow.status == 'Active' && selectedMenuRow.Status__c && "Published" &&
                        <div className="text-with-icon-1" onClick={() => { onClickOpenAction('create_form', 'menu'); }}>
                          <img className="download-source" src="/form.svg" alt="download source" />
                          <div className="label-3 poppins-normal-abbey-14px">
                            {" "}
                            Create Form
                          </div>
                        </div>
                      }
                      <div className="text-with-icon-1" onClick={()=>{onClickOpenAction('edit_details','menu');}}>
                        <img className="download-source" src="/Pencil.svg" alt="download source" />
                        <div className="label-3 poppins-normal-abbey-14px">
                          {" "}
                          Edit Template Details
                        </div>
                      </div>
                      {selectedMenuRow.status === "Active" &&
                      <div className="text-with-icon-1" onClick={()=>{onClickOpenAction('Inactive','menu')}}>
                        <img className="download-source" src="/inactive.svg" alt="download source" />
                        <div className="label-3 poppins-normal-abbey-14px">
                          {" "}
                          Deactivate Template
                        </div>
                      </div>
                      }
                      {selectedMenuRow.status === "Inactive" &&
                        <div className="text-with-icon-1" onClick={()=>{onClickOpenAction('Active','menu')}}>
                          <img className="download-source" src="/approved_active.svg" alt="download source" />
                          <div className="label-3 poppins-normal-abbey-14px">
                            {" "}
                            Activate Template
                          </div>
                        </div>
                      }
                      {selectedMenuRow.status === "Draft" &&
                        <div className="text-with-icon-1" onClick={() => { onClickOpenAction('Delete', 'menu') }}>
                          <img className="download-source" src="/delete.svg" alt="download source" />
                          <div className="label-3 poppins-normal-abbey-14px">
                            {" "}
                            Delete
                          </div>
                        </div>
                      }
                    </>
                  </div>
                </Menu>
              </Paper>
            </Stack>
          </>
        )}
        {warningDialodData &&
          <ConfirmDialog
            onCancel={() => { setWarningDialodData(null); }}
            onAccept={() => {
              if (warningDialodData.source == "menu") {
                if (warningDialodData.operation === 'Active' || warningDialodData.operation === 'Inactive') {
                  onactiveInactive(warningDialodData.operation, warningDialodData.operationItems[0])
                } else if (warningDialodData.operation === 'Delete') {
                  onDeleteTemplate(warningDialodData.operation, warningDialodData.operationItems[0]);
                }
              } else if (warningDialodData.source == "header") {
                if (warningDialodData.operation === 'Delete') {
                  deleteSelectedItems();
                }else if(warningDialodData.operation === 'Active' || warningDialodData.operation === 'Inactive') {
                  activeInactiveSelectedItems(warningDialodData.operation, warningDialodData.operationItems);
                }
              }
            }}
            DialogData={warningDialodData}
          />
        }
        {openBuildChecklist && (
          <BuildChecklistTemplate
            _templateOptions={templateOptions}
            templateId={templateId}
            templateList={currentTabTemplateListRef.current}
            projectTemplateId={projectTemplateId}
            onCancel={(refresh) => {
              //console.log('onCancel :: '+refresh);
              setOpenBuildChecklist(false);
              if (refresh == true) {
                fetchTemplateList();
                let tmsg = `Template Details has been updated.`;
                setToastMessage(tmsg);
              }
            }}
            onAccept={(sfObjR) => {
              const sObj = {value : sfObjR.Id, type : sfObjR.Form_Type__c, label : sfObjR?.Name };
              sObj.Description__c = sfObjR?.Description__c;
              handleFormDialogData();
              setCreateForm(sObj);
            }}
            credential={credential}
          />
        )}
        {openBuildPdf && (
          <BuildPdfTemplate
            _templateOptions={templateOptions}
            templateId={templateId}
            projectTemplateId={projectTemplateId}
            onCancel={(refresh) => {
              //console.log('onCancel :: '+refresh);
              setOpenBuildPdf(false);
              if (refresh == true) {
                fetchTemplateList();
                let tmsg = `Template Details has been updated.`;
                setToastMessage(tmsg);
              }
            }}
            onAccept={(sfObjR) => {
              const sObj = {value : sfObjR.Id, type : sfObjR.Form_Type__c, label : sfObjR?.Name };
              sObj.Description__c = sfObjR?.Description__c;
              handleFormDialogData();
              setCreateForm(sObj);
            }}
            credential={credential}
          />
        )}
      </div>
      {forEditTemplateDetail && (
        <ChecklistTemplateDetail
          sfObj={forEditTemplateDetail}
          credential={credential}
          templateOptions={templateOptions}
          currentStep={2}
          templateList={currentTabTemplateListRef.current}
          setCurrentStep={()=>{}}
          onCancel={() => {
            setEditTemplateDetail(undefined);
          }}
          onAccept={async (sfreocrd) => {
            //console.log('sfreocrd ::', sfreocrd);
            setSpinner(true);
            let rec = cloneDeep(sfreocrd) ;
            setEditTemplateDetail(undefined);
            const formData = new FormData();
            formData.append("recObj", JSON.stringify(rec));
            await axios.post(`${fetchUrl}/recSave/Form_Template__c`, formData)
            .then(async (res) => {
              //console.log("count:::: " + JSON.stringify(res));
              if (res.data.status === 200) {
                setToastMessage('Template Details has been updated.');
                fetchTemplateList();
              }else{
                setSpinner(false);
              }
            }).catch((err) => {
              console.log('recSave/Form_Template__c', err);
              setSpinner(false);
            });
          }}
        />
      )}
      {templatesForImport && templatesForImport.length > 0 &&
        <FileUploadProgress
          dialogData={importDialog}
          uploadQueueFolders={[]}
          uploadQueueFiles={templatesForImport}
          contentChanged={true}
          isTemplatesImported={isTemplatesImported}
          _handleCloseProcess={_handleCloseProcess}
        />
      }
        {openImportTemplates &&
          <ImportTemplates
            credential={credential}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            onCancel={() => {
              setCurrentStep(1);
              setOpenImportTemplates(false);
            }}
            onAccept={async (toInsertTemplatesIds, selectedRows) => {
              let dialogData = {header : `Importing ${selectedRows.length} item${selectedRows.length > 1 ? 's' : ''}`}
              setImportDialog(dialogData);
              setTemplatesForImport(selectedRows);
              setIsTemplatesImported('processing');
              if(toInsertTemplatesIds?.length > 0){
                let toInsert = [];
                for(let temId of toInsertTemplatesIds){
                  toInsert.push({Project__c:credential?.projectId , Form_Template__c : temId , Status__c : 'Active'});
                }
                if(toInsert?.length > 0 ){
                  setCurrentStep(1);
                  setOpenImportTemplates(false);
                  setSpinner(true);
                  const formData = new FormData();
                  formData.append("recLists", JSON.stringify(toInsert));
                  await axios.post(`${fetchUrl}/recSave/list/Project_Form_Template__c`, formData)
                    .then(async (res) => {
                      //console.log("count:::: " + JSON.stringify(res));
                      if (res.data.status === 200) {
                        setIsTemplatesImported('success');
                        fetchTemplateList();
                      }else{
                        setIsTemplatesImported('failed');
                      }
                    }).catch((err) => {
                      setIsTemplatesImported('failed');
                      console.log('recSave/Project_Form_Template__c', err);
                      setSpinner(false);
                    });
                }
              }
            }}
          />
        }
        {createForm &&
          <CreateProjectForm
            dialogData={createFormDialogData}
            sfObj={createForm}
            credential={credential}
            currentStep={2}
            setCurrentStep={() => { }}
            onCancel={(refresh, redirectId, formType) => {
              setCreateForm(undefined);
              if (refresh === true) {
                setOpenBuildPdf(false);
                setOpenBuildChecklist(false);
                refreshFormTableList();
              }
              if (redirectId && redirectId.length === 18) {
                setFormTypeForBuild(formType);
                openedProjectForm(redirectId);
              }else{
                let tmsg = `Forms have been generated.`;
                setToastMessage(tmsg);
                if(onClose){
                  onClose(0);
                }
              }
            }}
            callFrom="FormTemplateComponent"
          />
        }
        {formTypeForBuild == "Checklist" &&
          <BuildChecklistForm
            projectFormId={projectFormId}
            templateList={templateOptions}
            credential={credential}
            onCancel={(refresh, redirectId) => {
              setFormTypeForBuild(null);
            }}
          />
        }
        {formTypeForBuild == "PDF" &&
          <BuildPdfForm
            projectFormId={projectFormId}
            templateList={templateOptions}
            credential={credential}
            onCancel={(refresh, redirectId) => {
              setFormTypeForBuild(null);
            }}
          />
        }
      {toastMessage &&
        <ToastComponent message={toastMessage} handleClose={() => setToastMessage(null)} />
      }
    </div>
  );
};
export default FormTemplateComponent;
